@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:　din-2014-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4f4f4f;
  background-color: #c1c1c1;
}
